export const SocialData=[
    {
        platform:"Github",
        link:"https://github.com/jitendra-developer",
        icon: <i class="devicon-github-original"></i>
    },
    {
        platform:"Linkedin",
        link:"https://www.linkedin.com/in/jitendra-kumar/",
        icon: <i class="devicon-linkedin-plain"></i>
    }
]
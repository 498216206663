import React from 'react';
import './footer.css';

function Footer() {
    return (
        <div className='footer'>
            Made with ReactJs @2021
        </div>
    )
}

export default Footer

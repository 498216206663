import React from 'react';
import './seprator.css'

function Seprator() {
    return (
        <div className='seprator'>
            
        </div>
    )
}

export default Seprator

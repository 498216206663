import React from 'react'
import './web.css'
import SocialContact from '../../common/social-contact';
function Web() {
    
    return (
        <div className='web'>
            <SocialContact/>
        </div>
    )
}

export default Web
